body {
    margin: 0;

}

:focus {
    outline: 0 !important;
}

body::-webkit-scrollbar {
    display: none;
  }